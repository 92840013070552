<template>
  <article>
    <div class="content">
      <h1 id="title">
        4<span class="zero"><div class="circle"></div></span>4
      </h1>
      <h2>{{ $t('404.headline') }}</h2>
      <p class="detail">{{ $t('404.detail') }}</p>
      <BaseButton color="primary" to="/">{{ $t('404.button') }}</BaseButton>
    </div>
  </article>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style lang="scss" scoped>
article {
  max-width: 100vw;
  max-height: calc(100vh - 64px);
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    height: 100%;

    @media all and ($desktop) {
      max-width: $max-view-width;
      margin: 0 auto;
    }

    h1 {
      color: $foreground;
      font-size: 128px;
      margin: 0 0 8px 0;

      .zero {
        position: relative;
        display: inline-block;
        width: 90px;
        height: 90px;
        margin: 0 4px;

        .circle {
          bottom: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FFFFFF' stroke-width='7' stroke-dasharray='4,18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          animation: 60s linear infinite spin;

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        &::before,
        &::after {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          position: absolute;
          top: 30%;
          left: 50%;
          border-radius: 50%;
          background-color: $foreground;
          transform: translateX(-50%);
          animation: 3s linear infinite blink;

          @keyframes blink {
            0%,
            10%,
            14%,
            16%,
            20% {
              height: 8px;
              top: 30%;
            }
            12%,
            18% {
              height: 0;
              top: calc(30% + 4px);
            }
          }
        }

        &::before {
          transform: translateX(calc(-50% - 16px));
        }

        &::after {
          transform: translateX(calc(-50% + 16px));
        }
      }
    }

    h2 {
      color: $text-color;
      font-size: 1.5rem;
      text-align: center;
      margin: 0 0 12px 0;
    }

    .detail {
      color: $text-color;
      font-size: 1rem;
      text-align: center;
      margin: 0 0 48px 0;
    }
  }
}
</style>
